/* datepicker */
.react-datepicker{
  border: 1px solid #9F4DDA !important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: #9F4DDA !important;
}
.react-datepicker, .react-datepicker__header {
  background-color: #040305 !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: var(--whtclr) !important;
  border: 1px solid transparent!important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: var(--whtclr) !important;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: #040305 !important;
  color: var(--themegrnclr) !important;
}
/* datepicker end */
.wcm-overlay{
  z-index: 9999 !important;
}
#wcm-modal {
  z-index: 9999 !important;
}
.no_found{
  color: #fff;
  text-align: center;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.privacypolicy strong, .termss strong{
  font-size: 24px;
  line-height: 50px;
}
.overs_auto{
  overflow-y: auto;
}
.scheduleinfo .table>:not(caption)>*>*{
  border-bottom-width: 0px;
}
.pr120{
  padding-right: 120px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.calculations .row.mt-2{
  background: #040305;
  padding: 8px 2px;
  border: 1px solid #290d32;
}
.nbtn{
  background: var(--violentclr);
  border: 1px solid var(--violentclr);
  color: var(--whtclr);
  box-shadow: unset !important;
  border-radius: 4px;
}
.nbtn:hover{
  background: #9f4dda5e;
  border: 1px solid var(--violentclr);
  color: var(--whtclr);
}
.wraps{
  flex-wrap: wrap;
  gap: 0px 10px;
}
.docback.back_img{
  background: unset;
}
/* react quil */
.ql-editor{
  color: var(--whtclr) !important;
  height: 240px !important;
  min-height: 240px !important;
}
.editborder{
  border: 1px solid #9f4dda82;
  border-radius: 12px;
  box-shadow: 0 3px 10px #9f4dda61;
}
.tierdrop .dropdown-toggle::after{
  position: absolute;
  right: 2%;
  top: 45%;
}
.tierdrop .dropdown-toggle::after{
  position: absolute;
  right: 2%;
  top: 45%;
}

.tierdrop .dropdown-toggle, .tierdrop .btn:first-child:active{
  background: transparent;
  border: 1px solid var(--gold);
}
.tierdrop .dropdown-menu{
  background-color: #040305;
  border: 1px solid #b03dd382;
  color: #fff;
}
.tierdrop .dropdown-menu .dropdown-item{
  background-color: transparent;
  color: var(--white);
}
.tierdrop .dropdown-menu .dropdown-item:hover{
  color: var(--gold);
}
.copyicon{
  color: green;
    font-size: 25px;
}
.error_msg{
  color: red;
}
.fz svg{
  font-size: 25px;
  position: absolute;
  right: 5%;
  top: 25%;
}
.infodetail p, .infodetail ul {
  color: #fff;
  font-weight: 300;
}
.infodetail h1,h2,h3,h4,h5,h6{
  color: #fff;
}
.react-datepicker-wrapper{
  width: 100%;
}

/* loader */
.loader {
  display: flex;
  width: 48px !important;
  height: 48px !important;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid var(--themegrnclr);
  animation: prixClipFix 2s linear infinite ;
}
.loader::after{
  inset: 8px;
  transform: rotate3d(90, 90, 0, 180deg );
  border-color: var(--violentclr);
}

@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
}
/* loader end */

/* staking */
.walletsimg{
  width: 56px;
  height: 56px;
  object-fit: contain;
  aspect-ratio: 3 / 2;
}
.tok_add {
  word-wrap: break-word;
  word-break: break-all;
}
.smallheight{
  height: 600px;
  min-height: 600px;
}
.fw-400{
  font-weight: 400;
}
.f-16{
  font-size: 16px !important;
}
.docback{
  background: url("./images/spropad/docback.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
}
.labelback{
  background: url("./images/spropad/labelback.png") no-repeat;
  background-size: 100% 100%;
  /* width: 100%; */
  width: 190px;
  min-width: 190px;
  height: 45px;
  min-height: 45px;
  color: var(--whtclr);
  display: flex;
  align-items: center;
  justify-content: center;
}
.labelname{
  display: flex;
  align-items: center;
  position: absolute;
  right: 23%;
  top: -12px;
}
.rectangle{
  width: 21px;
  height: 21px;
}
.swapbalance{
  border: 1px solid #af40d1c2;
  background: #040305;
}
.maxbtn, .maxbtn:hover,.maxbtn.active{
    background-color: #9a35b9 !important;
    border-color: #9a35b9 !important;
    box-shadow: unset !important;
    padding: 4px 10px;
}
.prosimg{
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.botline{
  border-bottom: 1px solid gray;
  padding-bottom: 4%;
}
.max{
  position: absolute;
  right: 3%;
  top: 11%;
  /* top: 28%; */
}
.max.max1{
  top: 28%; 
}
.swapbalance .docback{
  background: unset;
  border: 0;
}
.tokenreqpage .form-check-input {
  width: 34px;
  height: 34px;
  margin-top: 0;
  border: 1px solid #af40d1c2;
  background: #040305;
}
.tokenreqpage .form-check-input[type=checkbox] {
  border-radius: 0px 12px 0px 12px ;
}
.tokenreqpage .form-check-input:focus {
  box-shadow: unset;
}
.tokenreqpage .form-check-label {
  margin-left: 15px;
}
.tnone{
  text-decoration: none;
}
.tokenreqpage .form-check{
  display: flex;
  align-items: center;
}
.tokenreqpage .form-check label{
  font-size: 20px;
}
.docback input{
  background: url("./images/spropad/inputbox.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  border: 0;
  box-shadow: unset;
  height: 50px;
  color: var(--whtclr);
}
.docback .react-datepicker__input-container input{
  /* background: url("./images/spropad/timerbox.png") no-repeat; */
  background-size: 100% 100%;
  width: 100%;
  border: 0;
  outline: 0;
  /* text-align: center; */
  text-align: left;
  height: 50px;
  padding-left: 15px;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef00 !important;
  opacity: 1;
}
.w-10{
  width: 12%;
}
.docback .form-control:focus {
  color: var(--whtclr);
  background-color: transparent;
  border-color: transparent;
  outline: 0;
  box-shadow: unset;
}
.docback textarea.form-control{
  background: url("./images/spropad/textareaback.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  border: 0;
  color: var(--whtclr);
  height: 150px;
  min-height: 150px;
  overflow: auto;
}
.select1 .dropdown button, .select1 .show>.btn-success.dropdown-toggle{
  background: url("./images/spropad/timerbox.png") no-repeat !important;
  background-size: 100% 100% !important;
  width:100% !important;
  height: 50px;
}
.select1.year .dropdown button, .select1.year .show>.btn-success.dropdown-toggle{
  background: url("./images/spropad/yearback.png") no-repeat !important;
  background-size: 100% 100% !important;
  width:100% !important;
  height: 50px;
  padding: 10px 30px;
}
.select1 .dropdown .dropdown-menu{
  background: #040305;
  border: 1px solid #9F4DDA !important;
  height: 280px;
  min-height: 280px;
  overflow-y: auto;
  min-width: 5rem;
}

.select1 .dropdown .dropdown-menu a::after {
  left: 15px;
  width: 50px;
}
.select1 .dropdown .dropdown-menu::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #000;
}
.select1 .dropdown .dropdown-menu::-webkit-scrollbar
{
	width: 7px;
	background-color: #000;
}
.select1 .dropdown .dropdown-menu::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--violentclr);
}
.select1 .dropdown-toggle::after{
  display: none;
}
.w300{
  width: 300px !important;
  min-width: 300px !important;
}
.allnavs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 32%;
  position: absolute;
  right: 16%;
  /* top: -10px; */
  top: -27px;
}
.scheduletabel{
  background: #040305;
  padding: 8px 2px;
  border: 1px solid #290d32;
}
.scheduleinfo .pagination{
  background: unset;
  margin: 0;
}
.scheduleinfo .pauseleftbtn {
  width: 34px;
  height: 34px;
}
.scheduleinfo .pagination button {
  margin-right: 0;
}
.scheduleinfo .pagination button:disabled {
  opacity: 0.4;
}
.navs{
  width: 26px;
  height: 26px;
}
.profilenav a {
  display: flex;
  align-items: center;
}
.allnavs a span{
  color: var(--whtclr);
  font-size: 20px;
  transition: 0.5s;
}
.allnavs a:hover span, .allnavs a.active span{
  background: -webkit-linear-gradient(left, #9F4DDA, #9F4DDA, #62E729, #62E729, #62E729, #62E729);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.poolstabel{
  background: url("./images/spropad/poolstabel.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  /* padding: 10% 4%; */
}
.rows-col-lg-4 .col p{
  color: var(--whtclr);
  font-size: 18px !important;
}
.infowalletback{
  background: url("./images/spropad/infowalletback.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
}
.teampath{
  clip-path: polygon(0 0, 86% 0, 100% 15%, 100% 100%, 16% 100%, 0 85%);
  width: 210px;
  height: 210px;
  object-fit: cover;
  margin: auto;
}
.partnerback{
  background: url("./images/spropad/partnerback.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
}
.teamimg{
  width: 210px;
  height: 210px;
  object-fit: cover;
}
.infodetail ul li{
  color: var(--subtextclr);
  font-size: 18px;
  margin-top: 12px;
}
.infonav{
  padding-left: 0px;
  display: flex;
}
.infonav li{
  list-style-type: none;
}
.infonav li:first-child a {
  margin-left: 0px;
}
.infonav li a{
  color: var(--whtclr);
  margin-left: 20px;
  padding: 7px 10px;
  font-size: 19px;
  font-weight: 400;
  border: 1px solid transparent;
  transition: 0.5s;
}
.infonav li a.active , .infonav li a:hover{
    background: #040305;
    border-radius: 6px;
    border: 1px solid #9f4dda8f;
    transition: 0.5s;
}
.infonav li a.active span , .infonav li a:hover span{
  background: -webkit-linear-gradient(left, #9F4DDA, #9F4DDA, #62E729, #62E729, #62E729, #62E729);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 0.5s;
}
.infoback{
  background: url("./images/spropad/infoback.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 5% 4%;
}
.infoback.tab2back{
  background: url("./images/spropad/tab2back.png") no-repeat !important;
  background-size: 100% 100% !important;
  width: 100% !important;
}
.projectback{
  background: url("./images/spropad/projectback.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
}
.bnbimg{
  width: 19px;
  height: 19px;
  object-fit: contain;
}
.infoback .tab-list li{
  /* min-width: 150px;
  width: 150px;
  padding: 9px 10px; */
  min-width: fit-content;
  width: fit-content;
  padding: 11px 11px;
}
.infoback .socialicons a {
  margin-left: 8px;
}
.addimg{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.infoback .socialicons a img{
  width: 40px;
  height: 40px;
}
.profssclip{
  filter: drop-shadow(1px 1px 5px #fff9);
}
.profclip{
  clip-path: polygon(1% 9%, 78% 9%, 100% 33%, 100% 100%, 23% 100%, 0 80%);
  width: 70px;
  height: 80px;
  object-fit: contain;
  background: #fff;
}
.timerbox{
  background: url("./images/spropad/timerbox.png") no-repeat;
  background-size: 100% 100%;
  /* width: 100%;  */
  width: 46px;
  display: flex;
  height: 46px;
  align-items: center;
  justify-content: center;
}
.timezone{
  background: url("./images/spropad/timezone.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
}
.upcoming{
  background: url("./images/spropad/upcomingback.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
}
.questions a:hover h5{
  color: var(--themegrnclr);
}
.stakingback{
  background: url("./images/spropad/stakingback.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 5% 4%;
}
.fgrid{
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}
.gridtable{
  background: url("./images/spropad/tierback.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
}
.imgpros{
  width: 48px;
  height: 48px;
  object-fit: contain;
}
.tab-list {
  list-style-type: none;
  padding: 0;
  display: flex;
}
.vioclr{
  color: var(--violentclr);
}
.doubleline{
  position: absolute;
    width: 100%;
    top: 35%;
    z-index: -1;
    left: 0;
}
.viewspro.claimbtn{
  width: 220px;
  min-width: 220px;
}
.tab-list li {
  font-size: 18px;
  display: inline-block;
  margin-right: 10px;
  padding: 5px 10px;
  cursor: pointer;
  color: var(--whtclr);
  width: 130px;
  min-width: 130px;
  text-align: center;
  font-weight: 500;
  /* border: 1px solid #ccc; */
}
.fw-300{
  font-weight: 300;
  font-size: 15px;
}
.tab-list li.active {
  background: url("./images/spropad/activetab.png") no-repeat;
  background-size: 100% 100%;
  /* background-color: #eee; */
  border-bottom: none;
}
.tab-list li.active span{
  background: -webkit-linear-gradient(left, #9F4DDA, #9F4DDA,  #62E729, #62E729);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}
.texted {
  background: -webkit-linear-gradient(left, #9F4DDA,#62E729,#62E729,#62E729,#62E729,#62E729,#62E729, #62E729,  #62E729, #62E729);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}
.innerpages .tab-content {
  border: 1px solid #ccc;
  padding: 10px;
}

.livepools{
  /* background: url("./images/spropad/liveback.png") no-repeat;
  background-size: 100% 100%;
  width: 100%; */
  border: 1px solid #663679e3;
  background-color: #040305;
  border-radius: 6px;
  box-shadow: 0 3px 30px #9f4dda42;
}
.livecardimg{
  border-radius: 8px;
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.imgprof{
  width: 60px !important;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}
.headtext .alltext::after {
  left: 90px !important;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: var(--themegrnclr) !important;
}
.rangeslider-horizontal .rangeslider__handle:after{
  display: none !important;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 17px !important;
  height: 17px !important;
}
.rangeslider-horizontal {
  height: 8px !important;
}
.endin{
  position: absolute;
  top: 3%;
  right: 5%;
  background: #0000008f;
  padding: 2px 10px;
  border-radius: 3px;
}
.timelinecircle{
  background-color: #25C852;
  border-radius:50%;
  display: inline-block;
  height:13px !important;
  width: 13px !important;
  animation: pulse-animation 2s infinite;
 
}
@keyframes pulse-animation  {
  0%{
      box-shadow:0 0 0 0 #25c8528c;
  }
  100%{
      box-shadow: 0 0 0 5px #25c8528c;
  }
}

.accord_table tbody tr{
  border: 0;
  border-color: transparent;
}
.grnclr{
  color: var(--themegrnclr) !important;
  font-size: 20px !important;
}
.accord_table{
  background: url("./images/spropad/liveback.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
 
}

.stakingback .accordion-button::after{
  display: none;
}
.finalogo{
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.claimbtn{
  background: url("./images/spropad/activetab.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;  
  background-color: unset !important;
  border: unset !important;
  box-shadow: unset !important;
  width: 180px;
  min-width: 180px;
  height: 50px;
  min-height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.claimbtn:hover{
  filter: drop-shadow(2px 4px 16px #21133b);
}
.ctext{
  background: -webkit-linear-gradient(left, #9F4DDA, #9F4DDA, #62E729, #62E729, #62E729, #62E729);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ctext1{
  background: -webkit-linear-gradient(left, #9F4DDA,#9F4DDA,#9F4DDA,#9F4DDA,#62E729,#62E729,#62E729, #62E729);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}
.dgrid{
  display: grid;
  grid-template-columns: auto auto auto auto auto; 
  width: 100%;
  gap: 25px;
}
.poolback{
  background: url("./images/spropad/poolcard.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 5% 5%;
}
.profileback{
  background: url("./images/spropad/profileback.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 5% 5%;
}
.addressback{
  background: url("./images/spropad/addressback.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 5% 5%;
}
.sharecard{
  background: url("./images/spropad/sharecard.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 5% 5%;
  height: 500px;
  min-height: 500px;
}
.sharecard:hover{
  filter: drop-shadow(2px 4px 56px #21133b);
}
.shareimg{
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.addressicon{
  width: 52px;
  height: 52px;
  object-fit: contain;
}
.redclr{
  color: #E53636;
}

@media only screen and (min-width:1600px) and (max-width: 1799px) {
  .projectback .grayclr{
    font-size: 16px !important;
  }
  .projectback .whtsclr{
    font-size: 16px !important;
  }
}

@media only screen and (min-width:1400px) and (max-width: 1699px) {
  .stakingback .grayclr{
    font-size: 17px !important;
  }
  .stakingback .fname, .stakingback .whtsclr {
    font-size: 17px !important;
}
.teamimg{
  width: 180px;
  height: 180px;
}
.teampath {
  width: 180px;
  height: 180px;
}
}


@media only screen and (min-width:1400px) and (max-width: 1599px) {
  .labelname {
    right: 19%;
}
  .scheduletabel .whtsclr{
    font-size: 16px !important;
  }
  .projectback .grayclr{
    font-size: 15px !important;
  }
  .projectback .whtsclr{
    font-size: 15px !important;
  }
  .infodetail .grayclr{
    font-size: 17px !important;
  }
  .allnavs {
    width: 35%;
    right: 15%;
    top: -24px;
}
}

@media only screen and (min-width:1200px) and (max-width: 1399px) {
  .labelname {
    right: 14%;
}
  .scheduletabel .whtsclr{
    font-size: 15px !important;
  }
  .allnavs {
    width: 40%;
    right: 9%;
    top: -24px;
}
  .rectangle {
    width: 16px;
    height: 16px;
}
.timerbox {
  width: 42px;
  height: 42px;
}
.upcoming{
  width: max-content;
}
.infonav li a {
  margin-left: 16px;
}
.projectback .grayclr{
  font-size: 13px !important;
}
.projectback .whtsclr{
  font-size: 13px !important;
}
.infonav li a {
  font-size: 17px;
}
.infodetail .grayclr{
  font-size: 16px !important;
}
}

@media only screen and (max-width: 1399px) {
  .stakingback .grayclr{
    font-size: 15px !important;
  }
  .stakingback .fname, .stakingback .whtsclr {
    font-size: 15px !important;
}
.stakingback .grnclr{
  font-size: 15px !important;
}
.stakingback .claimbtn {
  font-size: 15px !important;
}
}

@media only screen and  (max-width: 1199px) {
  .overauto{
    overflow: auto;
    padding-bottom: 12px;
  }
  .scheduleinfo .alltableoverflow{
    overflow: unset;
  }
  .dgrid{
    grid-template-columns: auto auto auto auto auto auto; 
  }
  .dgrid div{
    width: max-content !important;
  }
}




@media only screen and (min-width:992px) and (max-width: 1199px) {
  .labelname {
    right: 5%;
}
  .allnavs {
    width: 47%;
    right: 3%;
    top: 8px;
}
  .infonav li a {
    margin-left: 4px;
    padding: 7px 7px;
    font-size: 15px;
  }
  .infodetail .grayclr{
    font-size: 16px !important;
  }
}

@media only screen and  (max-width: 991px) {
  .sharecard{
    height: unset;
    min-height: unset;
  }
  .labelname {
    position: unset;
    margin-bottom: 5%;
    justify-content: center;
  }
  .infoback.tab2back{
    background: #0d0c0e !important;
  }
  .allnavs {
    position: unset;
    width: unset;
    margin-bottom: 5%;
  }
  .infoback  {
    background: #0d0c0e;
    border: 1px solid #9f4dda99;
  }
  .flexreverse{
      flex-wrap: wrap-reverse;
  }
  .menu{
    color: var(--whtclr);
    font-size: 30px;
  }
  .poolback {
    padding: 10% 6%;
}
.profileback{
  background: #0d0c0e;
  border: 1px solid transparent;
  border-image: linear-gradient(to right, #9F4DDA, #62E729) 1;
  border-radius: 6px;
}
}


@media only screen and (min-width:768px) and (max-width: 991px) {
  .w-10 {
    width: 19%;
}
  .infonav li a {
    margin-left: 8px;
    font-size: 17px;
}
.infodetail .grayclr{
  font-size: 16px !important;
}
  .profileback{
    padding: 5% 3%;
  }
  .allbenefits .fname{
    font-size: 16px !important;
  }
  .allbenefits .grayclr{
    font-size: 15px !important;
  }
}

@media only screen and  (max-width: 767px) {
  .docback{
    border: 1px solid #b03dd4a8;
    background: #040305;
  }
  .stakemodals .docback {
    border: 0;
  }
  .poolstabel{
    background: unset;
    border: 1px solid #9f4dda91;
  }
  .infonav li a {
    white-space: nowrap;
  }
  .infonav{
    width: 100%;
    overflow-x: auto;
    padding: 20px 0px;
  }
  .infodetail .grayclr{
    font-size: 16px !important;
  }
  .allbenefits .col-sm-3 .fname{
    font-size: 17px !important;
  }
  .gridtable {
    background: unset;
    border: 1px solid #9f4dda99;
  }
  .proimg{
    width: 70px;
    height: 70px;
    object-fit: contain;
  }
  .elites .fname{
    font-size: 16px;
  }
  .elites .grayclr {
    font-size: 14px !important;
}
  .doubleline {
    top: 35%;
}
  .profileback {
    padding: 10% 6%;
}
  .stakingback{
    background: #0d0c0e;
    border: 1px solid #6d2e81;
  }
  .accord_table,.poolback {
    background: #0d0c0e;
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #9F4DDA, #62E729) 1;
    border-radius: 6px;
  }
}

.discon{
  cursor: pointer;
}
.discon:hover{
  color: var(--themegrnclr);
}

@media only screen and (min-width:576px) and (max-width: 767px) {
  .tab-list li {
    font-size: 17px;
  }
}

@media only screen and  (max-width: 575px) {
  .modal{
    padding-left: 0px !important;
  }
  .networks{
    background: #0d0c0e;
    border: 1px solid #6d2e81;
    border-radius: 8px;
  }
  .labelback {
    background: unset;
    width: 150px;
    min-width: 150px;
    border: 1px solid #520060;
    background: #1b171c;
    font-size: 15px;
  }
  .w-10 {
    width: 19%;
}
  .docback input {
    height: 42px;
  }
  .docback{
    background: #0d0c0e;
    border: 1px solid #6d2e81;
  }
  .bb{
    border-bottom: 1px solid gray;
    width: fit-content;
  }
  .poolstabel{
    overflow-x: auto;
  }
  .rows-col-lg-4 .col p{
   /* white-space:nowrap; */
   width: 140px;
   min-width: 140px;
   word-wrap: break-word;
   word-break: break-word;
  }
  .poolstabel .row{
    flex-wrap: unset;
  }
  .navs {
    width: 21px;
    height: 21px;
}
.allnavs a span {
  font-size: 15px;
}
.allnavs {
  margin-bottom: 8%;
}
  .smtxt{
    font-size: 16px !important;
  }
  .partnerback{
    background: unset;
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #9F4DDA, #62E729) 1;
    border-radius: 6px;
  }
  .infoback {
    padding: 8% 4%;
}
.timerbox{
  width: 40px;
  height: 40px;
}
  .projectback .grayclr{
    font-size: 15px !important;
  }
  .projectback .whtsclr{
    font-size: 15px !important;
  }
  .allbenefits .col-sm-3 .grayclr{
    font-size: 15px !important;
  }
  .allbenefits .col-sm-3 .fname{
    font-size: 15px !important;
  }
  .profileback {
    padding: 10% 3%;
}
  .proimg{
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  .wfull{
    width: max-content !important;
  }
  .sharecard{
    background: #0d0c0e;
    /* border: 1px solid transparent;
    border-image: linear-gradient(to right, #9F4DDA, #62E729) 1; */
    border: 1px solid #9f4dda7d;
    border-radius: 8px;
  }
  .scheduleinfo .grayclr {
    font-size: 16px !important;
}
  .scheduletabel .whtsclr{
    font-size: 15px !important;
  }
  .claimcard .whtsclr, .claimcard .grayclr{
    font-size: 17px !important;
   }
   .tokenreqpage .themesbtn{
    width: 120px;
    min-width: 120px;
    height: 70px;
    min-height: 70px;
  }
  .tokenreqpage .themesbtn.backwebsite{
    width: 180px;
    min-width: 180px;
    height: 80px;
    min-height: 80px;
  }
  .docback input, .docback textarea.form-control{
    background: #040305 !important;
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #9F4DDA, #62E729) 1;
    border-radius: 6px;
    background-clip: padding-box, border-box !important;
  }
  .docback input.form-check-input:checked[type=checkbox]{
    background-image: url("./images/spropad/whitetick.svg") !important;
  }
  .docback .react-datepicker__input-container input{
    background: url("./images/spropad/timerbox.png") no-repeat !important;
    background-size: 100% 100% !important;
    width: 100% !important;
  }
  .swapbalance .themesbtn,  .stakemodals .themesbtn {
    width: 120px;
    min-width: 120px;
    height: 70px;
    min-height: 70px;
    font-size: 17px !important;
}
.addressdrop .themesbtn span {
  margin-top: -12px;
  font-size: 14px;
}
.elites .fname {
  min-height: 38px;
}
.doubleline {
  top: 26%;
}
.tab-list {
  flex-wrap: wrap;
  justify-content: center;
}
.tab-list li{
  margin-top: 5%;
}
.calculations .grayclr{
  font-size: 18px !important;
}
.calculations .fname{
  font-size: 18px !important;
}
}

@media only screen and  (max-width: 369px) {
  /* .addressdrop.dropdown button, .addressdrop.show>.btn-success.dropdown-toggle{
    font-size: 12px !important;
} */
.proimg {
  width: 36px;
  height: 36px;
}
.prosimg {
  width: 36px;
  height: 36px;
}
.calculations .grayclr{
  font-size: 16px !important;
}
.calculations .fname{
  font-size: 16px !important;
}
.doubleline {
  top: 24%;
}
.elites .fname {
  font-size: 14px;
}
.labelback {
  width: 130px;
  min-width: 130px;
  font-size: 14px;
}
.newhead .themesbtn {
  width: 135px !important;
  min-width: 135px;
  height: 66px;
  min-height: 66px;
  font-size: 12px !important;
}
.addressdrop .themesbtn span {
  margin-top: -12px;
  font-size: 13px;
}
  .claimcard .whtsclr, .claimcard .grayclr{
   font-size: 15px !important;
  }
  .navs {
    width: 18px;
    height: 18px;
}
  .tab-list li {
    font-size: 15px;
  }
  .col-3 {
    flex: 0 0 auto;
    width: 22%;
}
.allbenefits .col-3 .fname {
  font-size: 14px !important;
}
.gridtable img{
  width: 20px !important;
}
.infoback .tab-list li {
  /* min-width: 128px;
  width: 128px; */
}
.rectangle {
  width: 14px;
  height: 14px;
}
.timerbox {
  width: 36px;
  height: 36px;
}
.timerbox.whtsclr{
 font-size: 14px;
}
.upcoming .f-16{
  font-size: 14px !important;
}
.upcoming h5.fname{
  font-size: 16px !important;
}
.upcoming {
  width: 94%;
}

}