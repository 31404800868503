@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

:root{
    /* light_theme */
   --whtclr: #fff;  
   --themegrnclr:#62E729; 
   --headbackclr: #1A171C;
   --violentclr: #9F4DDA;
   --subtextclr: #858585;
}

.wcm-overlay{
    z-index: 9999 !important;
  }
  #wcm-modal {
    z-index: 9999 !important;
  }
.salessimg {
    width: 54px;
    height: 54px;
    object-fit: contain;
}
.calculations table tbody tr{
        background: #000;
        border: 1px solid #8d4bb959;
        vertical-align: middle;
        margin-bottom: 10px;
}
.calculations table thead tr{
    border-color: transparent;
}
.logoo{
    width: 300px;
}
body{
  background-color: #040305;
}
a{
  text-decoration: none;
}
.tnone{
    text-decoration: none !important;
  }
.fname{
  color: var(--whtclr);
}
.modal-content  .form-check-input{
    width: 1.6em;
    height: 1.6em;
    border: 2px solid transparent;
    border-image: linear-gradient(to right, #9F4DDA, #62E729) 1;
    background-color: unset;
}
/* .react-datepicker__current-month, .react-datepicker__navigation--previous, .react-datepicker__navigation-icon--next {
    display: none;
} */
.wallets{
  cursor: pointer;
}
.wallets:hover h3{
  color: var(--themegrnclr);
}
.modal-header{
  padding: 1rem 5rem;
}
.spacegif{
  position: absolute;
  width: 18%;
  transform: translate(-50%, -60%);
  left: 50%;
  z-index: -1;
}
.networks{
  background: url("./images/spropad/networks.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
}
.btn-close{
  filter: invert(1);
}
.binances{
  width: 40px;
  height: 40px;
}
.modal-content .form-check{
  display: flex;
}
.form-check-label{
  color: var(--whtclr) !important;
  margin-left: 9px;
  font-size: 24px;
  font-weight: 300;
}
.owl-carousel .owl-item .wallettext img{
  display: unset;
  width: unset;
}
.modal-content a{
  color: var(--violentclr);
  text-decoration: underline;
}
.modal-content{
  background: url("./images/spropad/walletback.png") no-repeat !important;
  background-size: 100% 100% !important;
  width: 100% !important;
}
.modal-header{
  border: 0;
}
.allflexrow::-webkit-scrollbar-track, .alltableoverflow::-webkit-scrollbar-track , .overauto::-webkit-scrollbar-track, .poolstabel::-webkit-scrollbar-track, .infonav::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 6px;
	background-color: #F5F5F5;
}
.allflexrow::-webkit-scrollbar, .alltableoverflow::-webkit-scrollbar, .overauto::-webkit-scrollbar,  .poolstabel::-webkit-scrollbar, .infonav::-webkit-scrollbar
{
  width: 10px;
  height: 8px;
	/* background-color: #F5F5F5; */
}

.allflexrow::-webkit-scrollbar-thumb, .alltableoverflow::-webkit-scrollbar-thumb, .overauto::-webkit-scrollbar-thumb, .poolstabel::-webkit-scrollbar-thumb, .infonav::-webkit-scrollbar-thumb
{
	border-radius: 6px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background: transparent linear-gradient(131deg, #9F4DDA 0%, #9F4DDA 100%) 0% 0% no-repeat padding-box;
}
body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 6px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 6px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background: transparent linear-gradient(131deg, #9F4DDA 0%, #9F4DDA 100%) 0% 0% no-repeat padding-box;
}

.accordion-item, .accordion-header, .accordion-button, .accordion-button:not(.collapsed), .accordion-button:focus{
  background-color: unset;
  box-shadow: unset;
  border: 0;
}
.accordion-header p{
  color: var(--whtclr);
  font-size: 22px;
}
.accordion-item{
  margin-bottom: 3%;
  position: relative;
  z-index: 4;
}
.accordion-item::after {
content: "";
background: url("./images/spropad/liness.png") no-repeat;
position: absolute;
left: 0;
width: 100%;
height: 3px;
bottom: 0;
}
.accordion-button::after{
  background: url("./images/spropad/pluss.png") no-repeat !important;
  background-size: 100% 100% !important;
  width: 30px !important;
  height: 30px !important;
}
.accordion-button:not(.collapsed)::after{
  background: url("./images/spropad/minuss.png") no-repeat !important;
  background-size: 100% 100% !important;
  width: 34px !important;
  height: 16px !important;
}
 .container{
  max-width: 90% !important;
 }
  .pagination {
    margin-top: 20px;
    margin-left: 6px;
    justify-content: center;
    background: url("./images/spropad/paginationback.png") no-repeat;
    background-size: 100% 100%;
    width: fit-content;
    padding: 1%;
    margin: auto;
  }
  .pauseleftbtn{
    background: url("./images/spropad/pauseleftbtn.png") no-repeat;
    background-size: 100% 100%;
    width: 35px;
  }
  .pauserightbtn{
    background: url("./images/spropad/pauserightbtn.png") no-repeat;
    background-size: 100% 100%;
    width: 35px;
  }
  .pagination button {
    padding: 5px 10px;
    margin-right: 5px;
    border: none;
    /* background-color: #f0f0f0; */
    cursor: pointer;
  }

  .pagination button.active {
    color: var(--themegrnclr) !important;
    background-color: transparent !important;
    font-size: 18px !important;
  }
   .pagination button:disabled {
    opacity: 0.4;
  }
  .btns{
    background-color: transparent;
    color: var(--whtclr);
    font-size: 18px;
  }
  .pagination button:hover {
    /* background-color: #ddd; */
  }
  


body{
    font-family: "Chakra Petch", sans-serif !important;
}
.btn{
  font-weight: 400 !important;
}
.counts .allrow{
    text-align: center;
}
.all{
    overflow: hidden;
}
.Homesections,.innerpages{
    padding-top: 160px;
}
.newhead{
    background: var(--headbackclr);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 77;
    padding: 16px 0px;
}
 .dropdown button, .show>.btn-success.dropdown-toggle{
    background: unset !important;
    border: unset !important;
    box-shadow: unset !important;
    font-size: 18px;
}
.tierdrop button.dropdown-toggle::after{
    position: absolute !important;
    right: 2%;
    top: 45%;
  }
  .tierdrop button.dropdown-toggle::after{
    position: absolute;
    right: 2%;
    top: 45%;
  }
  
  .tierdrop button.dropdown-toggle, .tierdrop .btn:first-child:active{
    background: transparent;
    border: 1px solid var(--gold);
  }
  .tierdrop .dropdown-menu{
    background-color: #040305;
    border: 1px solid #b03dd382;
    color: #fff;
  }
  .tierdrop .dropdown-menu .dropdown-item{
    background-color: transparent;
    color: var(--white);
  }
  .tierdrop .dropdown-menu .dropdown-item:hover{
    color: var(--gold);
  }
.stakingback  .dropdown button, .stakingback .show>.btn-success.dropdown-toggle {
    background:url("./images/spropad/searchback.png") no-repeat !important;
    background-size: 100% 100% !important;
    width: 240px !important;
    height: 50px;
    border: 0;
    text-align: left;
    position: relative;
}
.stakingback .dropdown-toggle::after {
    position: absolute;
    right: 5%;
    top: 45%;
}
 .dropdown .dropdown-menu{
    background: url("./images/spropad/dropback.png") no-repeat;
    background-size: 100% 100%;
    width: 100%;
} 
.cursor{
    cursor: pointer;
}
.fw-600{
    font-weight: 600;
}
 .dropdown .dropdown-menu a{
    color: var(--whtclr);
    font-size: 17px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    position: relative;
    font-weight: 500;
}
 .dropdown .dropdown-menu a:hover{
    background: unset;
    color: var(--themegrnclr);
}
 .dropdown .dropdown-menu a::after{
content:"";
background: url("./images/spropad/line.png") no-repeat;
background-size: 100% 100%;
position: absolute;
left: 17px;
width: 100px;
height: 2px;
bottom: 0;
}

.tierlists button.dropdown-toggle{
    background: #040305 !important;
    border: 1px solid transparent !important;
    border-image: linear-gradient(to right, #9F4DDA, #62E729) 1 !important;
    border-radius: 6px;
    background-clip: padding-box, border-box !important;
    height: 46px !important;
}
.tierlists button.dropdown-toggle::after{
    position: absolute;
    right: 5%;
    top: 45%;
}
.tierlists .tierdrop{
width: 240px !important;
}
.themesbtn{
   background: url("./images/spropad/connectbtn.png") no-repeat;
   background-size: 100% 100%;
   width: 100%;  
   background-color: unset !important;
   border: unset !important;
   box-shadow: unset !important;
   width: 180px;
   min-width: 180px;
   height: 80px;
   min-height: 80px;
   display: flex !important;
   align-items: center;
   justify-content: center;
   font-size: 18px !important;
   color: #fff;
}
.addressdrop.dropdown button, .addressdrop.show>.btn-success.dropdown-toggle{
    background: url("./images/spropad/connectbtn.png") no-repeat !important;
    background-size: 100% 100% !important;
    width: 100%; 
    border: unset !important;
    box-shadow: unset !important;
    font-size: 16px !important;
}
.addressdrop.dropdown .dropdown-menu{
    min-width: 12rem;
}
.addressdrop.dropdown .dropdown-toggle::after {
  margin-top: -12px;
}
.linecircle{
    position: absolute;
    right: 10%;
    width: 6%;
    bottom: 8%;
}
.themesbtn.applybtn{
    background: url("./images/spropad/applybtn.png") no-repeat !important;
    background-size: 100% 100% !important;
    margin: auto;
}
.themesbtn span{
    margin-top: -12px;
}
.themesbtn:hover{
    color: var(--themegrnclr) !important;
    filter: drop-shadow(2px 4px 6px #62e72936);
}



/* subpages */
.banner,.pos{
    position: relative;
    z-index: 5;
}
.banner::after{
    content: "";
    background: url("./images/spropad/firstbanner.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    width: 350px;
    height: 600px;
    left: -3%;
    top: 5%;
    z-index: -1;
}
.banner .fname{
    color: var( --violentclr);
    font-weight: 400;
}
.sectext{
    color: var(--themegrnclr);
    font-size: 52px;
    font-weight: 600;
}
.subpara{
    color: var(--subtextclr);
    font-size: 16px;
    line-height: 1.8;
}
.banner button[disabled]:hover{
    background: url("./images/spropad/connectbtn.png") no-repeat !important;
    background-size: 100% 100% !important;
    cursor: not-allowed;
    color: var(--whtclr) !important;
}
.btn:disabled {
    filter: grayscale(1) !important;
}
.projectlist{
    background: url("./images/spropad/projback.png") no-repeat;
    background-size: 100% 100%;
    width: 100% !important;  
    padding: 8% 0%;
}
.padl{
    padding: 0 10%;
}
.countno{
    font-size: 62px;
    font-weight: 600;
  background: -webkit-linear-gradient(#9F4DDA, #62E729);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.filterdrops{
    filter: drop-shadow(2px 0px 6px #9F4DDA) !important;
}
.proj{
    color: var(--whtclr);
    font-weight: 400;
}
.projvec{
    position: absolute;
    right: 10%;
    top: 10%;
    z-index: -1;
}
.greenround {
        position: absolute;
        right: 2%;
        top: 8%;
        width: 12%;
    }
.mainhead{
    color: var(--violentclr);
    font-weight: 600;
}
.mainsub{
   color: var(--themegrnclr);
}
.tokensale input{
   background:url("./images/spropad/searchback.png") no-repeat;
   background-size: 100% 100%;
   width: 100%;
   height: 50px;
   border: 0;
   padding-left: 46px;
   color: var(--whtclr);
}
.tokensale input:focus {
    color: #fff;
    border-color: transparent;
    background-color: transparent;
    box-shadow: unset;
}
.searchicon{
    color: var(--whtclr);
    font-size: 26px;
    position: absolute;
    top: 23%;
    left: 4%;
}
.tokencard{
    background:url("./images/spropad/cardback.png") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    /* height: 750px;   
    min-height: 750px; */
    position: relative;
    z-index: 5;
}


/* .allcard  */
.allcard .tokencard::after{
    content: ""; 
    background: url("./images/spropad/nerve1.gif") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    width: 200px;
    height: 46px;
    z-index: 1050;
}
.allcard:nth-child(3n+1) .tokencard::after{
    transform: rotate(90deg);
    bottom: 25%;
    right: -5%;
}
.allcard:nth-child(3n+2) .tokencard::after{
    transform: rotate(270deg);
    right: -15%;
    top: 33%;
}
.allcard:nth-child(3n+3) .tokencard::after{
    transform: rotate(90deg);
    left: -14%;
    bottom: 24%;
}
.poolsmap:nth-child(even){
   margin-top: 5%;
}
.tokencard:hover{
    filter: drop-shadow(2px 4px 56px #21133b);
}
.tokencard:hover .num{
    color: var(--violentclr);
}
.tokencard:hover .dayclr{
    color: var(--themegrnclr);
}
.tokencard .themesbtn {
    width: 200px;
    min-width: 200px;
    
}
.clippathimg{
    clip-path: polygon(84% 10%, 94% 0, 94% 54%, 100% 71%, 100% 94%, 0 93%, 0 74%, 5% 60%, 5% 26%, 18% 10%);
    height: 250px;
    min-height: 250px;
    width: 84%;
    margin: auto;
    margin-top: 4%;
    margin-left: 29px;
    position: relative;
    text-align: center;
}
.poolscard::after{
    content: ""; 
    background: url("./images/spropad/nerve1.gif") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    width: 160px;
    height: 40px;
    z-index: 1050;
}
.poolsmap:nth-child(1) .poolscard::after{
    transform: rotate(270deg);
    bottom: 18%;
    right: -5%;
}
.poolsmap:nth-child(2) .poolscard::after{
    transform: rotate(270deg);
    top: 28%;
    right: -15%;
}
.poolsmap:nth-child(3) .poolscard::after{
    transform: rotate(90deg);
    bottom: 36%;
    right: -6%;
}
.poolsmap:nth-child(4) .poolscard::after{
    transform: rotate(90deg);
    bottom: 19%;
    left: -13%;
}
.cardsimg{
    height: 250px;
    min-height: 250px;
    object-fit: cover;
}
.whtclr{
    color: var(--whtclr);
}
.padlr{
    padding-left: 12%;
    padding-right: 20%;
}
.binance{
    width: 26px;
    height: 26px !important;
}
.grayclr{
    color: #858585 !important;
    font-size: 19px !important;
}
.calculations .grayclr{
    font-size: 17px !important;
}
.whtsclr{
    color: var(--whtclr);
    font-size: 18px;
}
.borderbot{
  position: relative;
}
.borderbot::after{
    content:"";
    background: url("./images/spropad/greenlines.png") no-repeat;
    height: 2px;
    width: 100%;
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    /* top: 50%; */
    bottom: 0%;
}
.topvector{
    position: absolute;
    top: 7%;
    z-index: 0;
    right: 35px;
    height: 48px !important;
}
.standard{
    position: absolute;
    right: 13%;
    top: 14%;
    font-size: 17px;
}
.register,.stakingpools{
    background: url("./images/spropad/Stars.png") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    position: relative;
    z-index: 5;
}
.featurepos{
    position: absolute;
    width: 29%;
    transition: 0.5s;
}
.featureimg{
    width: 41%;
}
.feature1{
    left: 1%;
    top: 0%;
    text-align: end;
}
.feature2{
    left: 1%;
    top: 52%;
    text-align: end;
}
.feature3{
    right: 1%;
    top: 0%;
    text-align: start;
}
.feature4{
    right: 1%;
    top: 52%;
    text-align: start;
}
.feature5{
   bottom: -50%;   
}
@media screen and (min-width:1400px) {
    /* .stakingpools .col-xxl-3 {
        flex: 0 0 25% !important;
        max-width: 25% !important;
    } */
    .poolscard h2{
        font-size: 23px;
    }
}

.flash{
    width: 36%;
    position: absolute;
    left: 50%;
    z-index: -1;
    top: 0;
    transform: translate(-50%, 0%);
}
.alltext{
    position: relative;
    z-index: 5;
}
.tokenreqpage .alltext {
    z-index: 1;
}
.alltext::after{
    content: "";
    background: url("./images/spropad/underline.png") no-repeat;
    background-size: 100% 100%;
    height: 15px;
    position: absolute;
    left: 0;
    width: 200px;
    transform: translate(-50%, 0%);
    left: 50%;
    bottom: -16px;
}
.salestable{
    background: url("./images/spropad/salesbox.png") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding: 3% 2%;
}
.salestlist{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    overflow: auto;
}
.tableprof{
    width: 54px;
    height: 54px;
    object-fit: contain;
}
.regsbtn{
    background: url("./images/spropad/regsbtn.png") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100px;
    position: relative;
}
.regtext{
    position: absolute;
    right: 5%;
    font-size: 17px;
    top: 22%;
}
.Homesections .nav-pills .nav-link.active,.Homesections .nav-pills .nav-link:hover{
    /* background: url("./images/spropad/connectbtn.png") no-repeat !important;
    background-size: 100% 100% !important; */
    color: var(--themegrnclr) !important;
    filter: unset;
}
.Homesections .nav-pills .nav-link{
    background: url("./images/spropad/connectbtn.png") no-repeat !important;
    background-size: 100% 100% !important;
    width: 200px;
    min-width: 200px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    color: var(--whtclr);
    filter: grayscale(1);
}
.Homesections .nav-pills .nav-link span{
    margin-top: -6%;
}
.Homesections .nav-item{
    margin-left: 2%;
}
.subheaders{
    background: #1b171c;
    position: absolute;
    top: 0px;
    z-index: 777;
    height: 500px;
    width: 70px;
}
.leftrotate {
    position: absolute;
    transform: rotate(270deg);
    bottom: 8%;
    left: 30px;
    transform-origin: left;
    display: flex;
}
.headertop{
    position: absolute;
    left: 35%;
    top: 8%;
    width: 30%;
}
/* .react-datepicker__navigation--previous{
    display: none !important;
} */
.leftrotate a{
    color: var(--whtclr) !important;
    margin-right: 35px;
    font-size: 18px;
    position: relative;
    z-index: 4;
    transition: 0.5s;
    font-weight: 500;
}
.leftrotate a:hover, .leftrotate a.active{
    background: -webkit-linear-gradient(left, #62E729, #62E729, #62E729, #62E729, #9F4DDA, #9F4DDA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.5s;
}

.leftrotate a .unders{
   display: none;
}
.leftrotate a:hover .unders, .leftrotate a.active .unders{
    display: block;
    width: -webkit-fill-available;
    position: absolute;
    bottom: -11px;
    left: 0;
    height: 9px;
}

@media screen and (min-width:1400px) {
    .accordion-item::after {
        left: 10%;
    }
    .col-xxl-4{
        flex: 0 0 33.33333% !important;
        max-width: 33.33333% !important;
    }
}

.Homesections .card{
    background: unset !important;  
    position: relative; 
    margin-bottom: 2%;
}
.Homesections .sideline{
    /* position: absolute;
    left: 8%;
    bottom: 0;
    width: 4%; */
    position: absolute;
    left: -3%;
    bottom: 0;
    width: 4%;
    top: 2%;
}
.Homesections .card::after{
    content: "";
    background: url("./images/spropad/liness.png") no-repeat;
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    bottom: 0;
}
.Homesections .btn-link{
    width: 100%;
    text-align: left;
    color: var(--whtclr);
    font-size: 24px;
    position: relative;
}
.Homesections .btn-link:hover,.Homesections .btn-link:focus{
    box-shadow: unset;
    color: var(--whtclr);
    text-decoration: none;
}
/* .Homesections .btn-link::after{
    content: "";
    background: url("./images/spropad/minuss.png") no-repeat;
    position: absolute;
    width: 60px;
    height: 20px;
    right: 0;
} */
.plusminus{
    width: 30px;
    height: 30px;
    object-fit:contain;
}
.twinkle{
    position: absolute;
    top: 4%;
    width: 75%;
}
.launch_spropad{
    margin-top: 12%;
}

/* footers */
.footers{
    background: url("./images/spropad/footer\ box.png") no-repeat;
    background-size: 100% 100%;
    width: 100%; 
    position: relative;
    z-index: 5;
}
.footerlogo{
    width: 350px;
}
.socialicons a{
    margin-left: 26px;
}
.socialicons a:hover{
    /* filter: drop-shadow(2px 4px 56px #21133b); */
    filter: invert(1);
}
.socialicons a img{
    width: 58px;
    height: 58px;
    object-fit: contain;
    margin-top: 12px;
}
.sociallist{
    display: flex;
    justify-content: space-between;
}
.sociallist a{
    color: var(--whtclr) !important;
    font-size: 22px;
    font-weight: 500;
}
.sales a:hover .viewall{
  color: var(--themegrnclr);
}
.salestablefilter:hover{
    filter: drop-shadow(2px 4px 52px #21133b);
}
.wallettext{
    display: flex;
    align-items: center;
    justify-content: end;
    background: -webkit-linear-gradient(left, #9F4DDA,#9F4DDA,#9F4DDA,#9F4DDA,#9F4DDA,#62E729,#62E729);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    z-index: 4;
}
.wallettext::after{
    content: '';
    /* background: linear-gradient(90deg, #9F4DDA 0%, #62E729 100%); */
    width: 130px;
    height: 40px;
    filter: blur(49px);
    position: absolute;
    z-index: -1;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: var(--violentclr) !important;
}
.owl-theme .owl-dots .owl-dot span {
    width: 13px !important;
    height: 13px !important;
}
.spaceleft{
    position: absolute;
    right: 3%;
    top: 0%;
    z-index: 0;
}
.spaceround{
    position: absolute;
    right: 0px;
    top: -8%;
    width: 12%;
    z-index: 0;
}
.sociallist a:hover{
    background: -webkit-linear-gradient(left,#62E729,#62E729,#62E729, #62E729,#9F4DDA,#9F4DDA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.graya{
    color: var(--subtextclr);
    font-size: 16px;
    position: absolute;
}
.graya:hover{
    color: var(--themegrnclr);
}
.graya.copyrighta:hover{
    color: var(--subtextclr);
}
.privacya{
    bottom: 2%;
    left: 10%;
}
.termsa{
    bottom: 2%;
    left: 31%;
}
.copyrighta{
    bottom: 2%;
    right: 32%;
    cursor: initial;
}
.launchback{
    background: url("./images/spropad/launchback.png") no-repeat;
    background-size: 100% 100%;
    width: 100%; 
    padding: 7% 2%;
    overflow: hidden;
    position: relative;
    z-index: 4;
}
.nerve{
    position: absolute;
}
.nerve1{
    bottom: -25%;
    left: 38%;
    width: 22%;
}
.nerve2{
    width: 13%;
    right: -6%;
    transform: rotate(270deg);
    top: 42%;
}
.filterdrop {
    filter: drop-shadow(2px 4px 56px #21133b);
}

.flexreverse .smtxt {
    word-break: break-all;
    line-height: 28px;
}


@media only screen and (min-width:2300px) {
    .clippathimg {
        margin-left: 40px;
    }
    .allcard:nth-child(3n+2) .tokencard::after {
        right: -12% !important;
    }
    .allcard:nth-child(3n+3) .tokencard::after {
        left: -10% !important;
    }
}

@media only screen and (min-width:2100px) {
    .allcard .tokencard {
        width: 95%;
        margin: auto;
    }
    .allcard:nth-child(3n+2) .tokencard::after {
        right: -14%;
    }
    .allcard:nth-child(3n+3) .tokencard::after {
        left: -12%;
    }
    .poolscard.tokencard {
        width: 90% !important;
        margin: auto;
    }
    .clippathimg {
        width: 83%;
    }
    .topvector {
        right: 48px;
    }
}

@media only screen and (min-width:1920px) and (max-width:2099px) {
    .poolscard.tokencard {
        width: 97% !important;
    }
}
@media only screen and (min-width:1920px) {
    .allcard .borderbot::after {
    left: 70%;
}
}

@media only screen and (min-width:1800px) {
    .nerve1 {
        bottom: -22%;
        left: 38%;
        width: 18%;
    }
    .banner .px-xxl-5{
        padding-left: 8rem !important;
        padding-right: 8rem !important;
    }
    .banner::after {
        left: 0%;
        top: 0%;
        width: 370px;
        height: 700px;
    }
    .subheaders {
        height: 890px;
        width: 95px;
    }
    .leftrotate {
        left: 42px;
        bottom: 20%;
    }
    .headertop {
        width: 45%;
    }
}

@media only screen and (min-width:1600px) {
    .regtext{
        font-size: 23px;
        top: 19%;
    }
    .regsbtn{
        height: 120px;
    }
    .subparapl{
        padding-left: 30%;
    }
    .spaceround{
        right: -10px;
    }
}


@media only screen and (min-width:1600px) and (max-width: 1899px) {
  .feature1,.feature3{
    top: -21%;
  }
  .feature5 {
    bottom: -65%;
}
}

@media only screen and (min-width:1600px) and (max-width: 1799px) {
    .allcard:nth-child(3n+1) .tokencard::after {
        right: -10%;
    }
    .allcard:nth-child(3n+2) .tokencard::after {
        right: -18%;
    }
    .allcard:nth-child(3n+3) .tokencard::after {
        left: -17%;
    }
}

@media only screen and (min-width:1400px) and (max-width: 1799px) {
    .banner::after {
        left: 0;
    }
    .subheaders {
        height: 700px;
        width: 70px;
    }
    .leftrotate {
        bottom: 20%;
    }
    .headertop {
        width: 50%;
    }
}


@media only screen and (min-width:1400px) and (max-width: 1699px) {
    .graya.copyrighta{
        font-size: 13px;
        bottom: 3%;
    }
    .tokencard::after {
        right: -7%;
    }
    .stakingpools .col-xxl-3 {
        flex: 0 0 auto;
        width: 33%;
        margin: auto;
        margin-top: 5%;
    }
}

@media only screen and (min-width:1400px) and (max-width: 1599px) {
    .featurepos {
        width: 30%;
    }
    .feature5 {
      bottom: -78%;
  }
    .feature1,.feature3 {
        top: -15%;
    }
    .features .subpara{
       font-size: 13px;
    }
    .feature2,.feature4{
       top: 49%;
    }
    .clippathimg {
        margin-left: 25px;
    }
    .allcard.col-xxl-4 {
        flex: 0 0 43.33333% !important;
        max-width: 43.33333% !important;
        margin: auto;
    }
}




.featurepos:hover {
   background: url("./images/spropad/featurecard.png") no-repeat;
   background-size: 100% 100%;
   transition: 0.5s;
}
.featurepos:hover h3 {
    color: var(--themegrnclr);  
}
.featurepos:hover .num{
    color: var(--violentclr);
}
.featurepos:hover .dayclr{
    color: var(--themegrnclr);
}

@media only screen and (min-width:1200px) and (max-width: 1399px) {
   .allcard .tokencard {
        width: 98%;
        margin: auto;
    }
    .poolscard.tokencard {
        width: 86%;
        margin: auto;
    }
  .launch_spropad {
    margin-top: 19%;
}
    .featurepos {
        width: 30%;
    }
    .feature5 {
        bottom: -90%;
    }
    .feature1,.feature3 {
        top: -26%;
    }
    .features .subpara{
       font-size: 12px;
    }
    .feature2,.feature4{
       top: 49%;
    }
    .nerve1 {
        bottom: -22%;
        left: 38%;
        width: 22%;
    }
    .spaceleft{
        right: 2%;
    }
    .spaceround {
        right: -8px;
        top: -5%;
        width: 13%;
    }
    .Homesections .themesbtn {
        width: 150px;
        min-width: 150px;
        height: 70px;
        min-height: 70px;
        font-size: 15px !important;
    }
    .projvec {
        right: 0%;
    }
    .subheaders {
        height: 680px;
    }
    .headertop {
        width: 50%;
    }
    .leftrotate {
        bottom: 20%;
    }
    .banner::after {
        top: 0%;
        display: none;
    }
    .padl {
        padding: 4% 10%;
    }
    .graya.copyrighta{
        font-size: 13px;
        bottom: 3%;
        right: 30%;
    }
}

.owl-theme .owl-dots {
    text-align: end !important;
}
.native_sales .owl-theme .owl-dots{
    text-align: center !important;
}
.owl-prev{
    position: absolute;
    left: 0;
    font-size: 50px !important;
    color: #fff !important;
    top: 40%;
    width: 50px;
    height: 50px;
    background: #9F4DDA !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.owl-next{
    position: absolute;
    right: 0;
    font-size: 50px !important;
    color: #fff !important;
    top: 40%;
    width: 50px;
    height: 50px;
    background: #9F4DDA !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.greenroundss{
    width: 25%;
    position: absolute;
    left: 50%;
    top: 53%;
    transform: translate(-50%, -50%);
    z-index: 0;
    filter: brightness(0.6);
}
.featuresetting{
    width: 11%;
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
    z-index: 0;
}
@media only screen and (max-width: 1199px) {
    .banner::after, .greenroundss, .featuresetting{
        display: none;
    }
    .owl-theme .owl-dots {
        text-align: center !important;
    }
    .alltableoverflow{
        overflow: auto;
        padding-bottom: 25px;
    }
    .featurepos .num {
      color: var(--violentclr);
    }   
    .featurepos .dayclr{
      color: var(--themegrnclr);
    }
    .spaceleft,.spaceround,.featureimg{
        display: none;
    }
    .featurepos{
        position: unset;
        flex: 0 0 50%;
        max-width: 50%;
        background: url("./images/spropad/featurecard.png") no-repeat;
        background-size: 97% 100%;
        background-position: center;
    }
    .feature1,.feature2{
        text-align: start;
    }
}
@media only screen and (min-width:992px) and (max-width: 1199px) {
    .tab-list{
        flex-wrap: wrap;
        justify-content: center;
    }
    .grnclr {
        font-size: 16px !important;
    }
    .poolback {
        padding: 5% 4% !important;
    }
    .salestable .grayclr {
        font-size: 16px !important;
    }
    .salestable .whtsclr {
        font-size: 15px !important;
    }
    .allcard:nth-child(3n+1) .tokencard::after {
        right: -10%;
    }
    .allcard:nth-child(3n+2) .tokencard::after {
        right: -20%;
    }
    .allcard:nth-child(3n+3) .tokencard::after {
        left: -19%;
    }
    .clippathimg{
        margin-left: 20px;
    }
    .launchback h4{
        font-size: 17px;
    }
    .nerve1 {
        bottom: -21%;
    }
    .padl {
        padding: 4% 10%;
    }
    .projvec {
        right: -8%;
    }
    .graya.copyrighta{
        font-size: 11px;
        bottom: 3%;
        right: 29%;
    }
    .graya {
        font-size: 13px;
    }

}
@media only screen and (min-width:768px) and (max-width: 991px) {
  

    .allcard .tokencard {
        width: 80%;
        margin: auto;
    }
    /* .subheaders {
        width: 40px;
    } */
    .leftrotate {
        left: 18px;
    }
    .leftrotate a {
        font-size: 16px;
    }
    .featurepos h3 {
       font-size: 22px !important;
    }
    .nerve1 {
        bottom: -14%;
    }
    .nerve2 {
        top: 44%;
    }
    .launchback{
        padding: 10% 2%;
    }
    .launchback h1{
        font-size: 24px;
    }
    .launchback h4{
        font-size: 14px;
    }
    .padl {
        padding: 4% 8%;
    }
    .countno {
        font-size: 40px;
    }
    .proj{
        font-size: 15px;
    } 
    .projvec {
        right: 0%;
        width: 28px;
    }
    .banner::after {
        width: 300px;
        height: 500px;
        top: 0%;
    }
}
@media only screen and (max-width: 991px) {
    .accordion-item::after {
        background: unset;
    }
    .accord_table .accordion-header{
          border-bottom: 1px solid #62e72987;
          width: fit-content;
    }
    .mobiledropmenu.dropdown-menu {
        min-width: 14rem;
        width: 14rem !important;
    }
    .subheaders,.tokencard::after{
        display: none;
    }
    .offcanvas-end{
        background: #040305 !important;
    }
    .offcanvas-body a{
      display: block;
      color: var(--whtclr);
      font-size: 20px;
      margin-bottom: 5%;
    }
    .poolscard.tokencard::after{
         display: none;
    }
    .graya {
        position: unset;
        font-size: 14px;
    }
    .salestlist {
        /* width: max-content; */
        gap: 25px;
    }
    /* .salestable {
        width: fit-content;
    } */
    .salestable {
        background: unset;
        border: 1px solid #9f4ddabf;
        padding: 7% 3%;
        border-radius: 6px;
        width: 100%;
    }
    .salestlist {
        grid-template-columns: auto auto auto;
    }
}
@media only screen and (max-width: 767px) {
    .poolsmap:nth-child(odd) {
        margin-top: 5%;
    }
    .spacegif{
        display: none;
    }
    .featurepos {      
       flex: 0 0 100%;
        max-width: 100%;
        background-size: 100% 100%;
    }
    .nerve{
        display: none;
    }
    .launchback {
        padding: 12% 2%;
    }
    .launchback h1{
        font-size: 24px;
    }
    .sociallist a {
        font-size: 19px;
    }
    .projectlist {
        padding: 30% 0%;
        margin-top: -10%;
    }   
    .newhead .themesbtn{
        width: 166px !important;
        min-width: 166px;
        height: 66px;
        min-height: 66px;
        font-size: 14px !important;
    }
    .Homesections .themesbtn {
        width: 150px;
        min-width: 150px;
        height: 70px;
        min-height: 70px;
        font-size: 15px !important;
    }
    /* .newhead .dropdown button, .show>.btn-success.dropdown-toggle {
        font-size: 14px;
    } */
    .newhead .dropdown .dropdown-menu a {
        font-size: 15px;
    }
    .logoo{
        width: 150px;
    }
    .projvec {
        top: 16%;
        width: 30px;
    }
    .banner::after {
        width: 300px;
        height: 560px;
        top: 0%;
    }
    .padl {
        padding: 2% 10%;
    }
    .graya {
        font-size: 12px;
    }
    .modal-content .form-check-input {
      width: 2.4em;
  }
}
@media only screen and (max-width: 575px) {
    .owl-prev,.owl-next{
        font-size: 38px !important;
        width: 36px;
        height: 36px;
    }
    .linecircle{
        display: none;
    }
    .tokensale .tokencard{
        border: 1px solid #663679e3;
        background: unset;
    }
    .tokensale .clippathimg{
        clip-path: unset;
        margin-bottom: 6%;
        margin-left: auto;
        width: 90%;
    }
    .tokensale .clippathimg .cardsimg {
        border-radius: 6px;
    }
    .Homesections {
        padding-top: 100px;
    }
    .topvector {
        right: 15px;
        top: 1%;
    }
    .sectext {
        font-size: 37px;
    }
    .Homesections .banner .themesbtn {
        width: 100%;
        border: 1px solid var(--violentclr) !important;
        background: unset;
        height: 46px;
        min-height: 46px;
    }
    .Homesections .banner .themesbtn span{
       margin-top: 0px;
    }
    .accordion-header p {
        font-size: 20px;
    }
    .salestlist div:nth-child(even){
        text-align: end;
    }
    .salestlist{
        grid-template-columns: auto auto;
        width: 100%;
    }
    .salestable{
        background: unset;
        border: 1px solid #9f4ddabf;
        padding: 7% 3%;
        border-radius: 6px;
        width: 100%;
    }
    .allflexrow{
      display: grid !important;
      grid-template-columns: auto auto;
      }
      .Homesections .nav-pills .nav-link{
        width: 160px;
        min-width: 160px;
        font-size: 16px !important;
        gap: 10px;
      }
  .modal-header {
    padding: 1rem 1rem !important;
}
.modal-content {
  background: #0d0c0e !important;
  border: 1px solid #b03dd37d;
}
  .modal-content .form-check-input {
    width: 2.6em;
}
  .form-check-label {
    font-size: 17px;
}
  .container {
    max-width: 97% !important;
}
.clippathimg {
  margin-left: 20px;
}
.standard {
  right: 11%;
  top: 8%;
}
.launchback h4.whtclr {
    font-size: 20px;
}
  .tokensale .padlr {
    padding-right: 8%;
    padding-left: 8%;
}
.padlr .grayclr {
  font-size: 17px;
}
.padlr .whtsclr{
  font-size: 17px;
}
    .sideline, .tokencard:nth-child(1)::after{
        display: none;
    }
    .featurepos:hover{
        background: unset;
    }
    .poolscard h2{
      font-size: 22px;
    }
    .Homesections .card-header p{
        font-size: 18px;
    }
    .featurepos{
        background: unset;
        border: 1px solid #0e5a0e;
    }
    .launchback{
        background: unset;
        background-image: linear-gradient(to right, #570076, #dbcccc21, #0f602acf);
        border-radius: 16px;
    }
    .footerlogo {
        width: 150px;
    }
    .footers{
        background: unset;
        background-color: #1b181d;
        border: 1px solid #04762885;
    }
    .footlast a{
        display: block;
        margin-top: 3%;
        text-align: center;
    }
    .socialicons a {
        margin-left: 10px;
    }
    .socialicons a img {
        width: 30px;
        height: 30px;
    }
    .sociallist a {
        font-size: 15px;
    }
    .banner::after, .projvec{
        display: none;
    }
    .projectlist {
        padding: 0;
        margin-top: 0;
        background: unset;
    }   
    .newhead .themesbtn {
        width: 148px !important;
        min-width: 148px;
        height: 66px;
        min-height: 66px;
        font-size: 12px !important;
    }
    /* .newhead .dropdown button,.show>.btn-success.dropdown-toggle { 
        font-size: 26px;
    } */
    .newhead .dropdown-toggle::after{
        font-size: 17px;
    }
    .newhead .dropdown .dropdown-menu a {
        font-size: 13px;
    }
    .proficon{
        margin-top: -3px;
    }
    .regtext {
        font-size: 14px;
        top: 24%;
    }
}
@media only screen and (max-width: 374px) {
    .padl .proj{
        font-size: 19px;
    }
    .padl .countno{
        font-size: 50px;
    }
    .alltableoverflow .grayclr{
        font-size: 16px !important;
    }
    .alltableoverflow .whtsclr{
        font-size: 15px;
    }
    .Homesections .nav-pills .nav-link{
        width: 140px;
        min-width: 140px;
        font-size: 15px !important;
      }
  .clippathimg {
    margin-left: 15px;
}
  .standard {
    right: 15%;
  }
    /* .newhead .dropdown button,.show>.btn-success.dropdown-toggle { 
        font-size: 21px;
    } */
    .newhead .dropdown-toggle::after{
        font-size: 14px;
    }
    .logoo{
        width: 140px;
    }
    .regtext {
        font-size: 12px;
    }
    .regsbtn{
        height: 80px;
    }
}